import {useState} from 'react'
import TipoEvento from './tipo_evento.js'
import Imagen from './imagen.js'
import DataEvento from './data_evento.js'

const Evento = ((props) => {
  const [nombre, setNombre] = useState('')
  const [cabecera, setCabecera] = useState('')
          
  return (<>
    <div className="flex flex-col w-full h-5/6 overflow-y-auto">
      {props.hacer!=='' ?
      <div className="flex flex-row justify-center my-5">
        <label className="text-lg mr-5">Nombre</label>
        <input 
          className="outline-0 bg-white w-64 focus:border-b-2 focus:border-red-500"
          value={nombre}
          onChange={e => setNombre(e.target.value)}
        />
      </div>
      : '' }
      {nombre.trim()!=='' && props.hacer==='crear' ? <TipoEvento evento_t={props.evento_t} setEvento_t={props.setEvento_t}/> : ''}
      {props.evento_t!=='' ? <Imagen cabecera={cabecera} setCabecera={setCabecera }/> : ''}
      {nombre.trim()!=='' && props.evento_t!==''?
        <DataEvento token={props.token} back={props.back} nombre={nombre} evento_t={props.evento_t} imagen = {cabecera} hacer={props.hacer} recargar={props.recargar} setRecargar={props.setRecargar} />
      : '' }
   </div>
    
  </>) 
})

export default Evento