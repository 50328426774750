import { useState, useEffect } from 'react'
import Inscripto from './inscripto_cirugia.js'

const PORT_BACK = 8003
const SERVER_URL = "https://inscripcion.ar:"

const back = SERVER_URL + PORT_BACK + "/"

export default function Bar2024(props) {
  const [preinscriptos, setPreinscriptos] = useState([])
  const [c_e, setC_E] = useState(0)
  const [apellido, setApellido] = useState('')
  const [nombre, setNombre] = useState('')
  const [email, setEmail] = useState('')
  const [dni, setDni] = useState('')
  const [categoria, setCategoria] = useState('Todos')
  const [provincias, setProvincias] = useState([])
  const [respuesta, setRespuesta] = useState(0)
  const [total, setTotal] = useState(0)
  const [form, setForm] = useState({})
  

    
  function traer_pre () {
    fetch(back + 'admin/traer_inscriptos', {
      method: 'GET',
      headers: {
      'Authorization': 'Bearer ' + props.token
    }})
    .then (response => response.json())
    .then (a => {
      const t = a.map((e) => {return {...e, s: false}}) 
      setPreinscriptos(t)
      setC_E(0)
      setTotal(t.length)
    })
  }


  useEffect(() => {
    function traer_provincias () {
      fetch(back + 'traer_provincia', {
        method: 'GET',
        headers: {
        'Authorization': 'Bearer ' + props.token
      }})
      .then (response => response.json())
      .then (a => setProvincias(a))
    }
    traer_provincias()
    traer_pre()
  }, [respuesta])

  
  function elegir (z) {
    if (props.quien.rol===1) {
      elegir_uno(z)
      return 
    }
    const t =  preinscriptos.map (e => {
      if (e.id===z)
        e.s = !e.s
      return e
    })
    setPreinscriptos(t)
    const cuantos = t.filter(a => a.s).length
    setC_E(cuantos)
    }

  function elegir_uno (z) {
    const cuantos = preinscriptos.filter(a => a.s)
    if (! cuantos.length) {
      const t =  preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
      })
      setPreinscriptos(t)
      setC_E(1)
    } else {
      todo(false)
      if (cuantos[0].id !== z) {
        const t = preinscriptos.map (e => {
        if (e.id===z)
          e.s = true
        return e
        })
        setPreinscriptos(t)
        setC_E(1)
      } else {
        const t = preinscriptos.map (e => {
          if (e.id===z)
            e.s = false
          return e
          })
          setPreinscriptos(t)
          setC_E(0)
      }
    }
  }
  
  function todo(z) {
    if (props.quien.rol===1 && z) return
    const t =  preinscriptos.map (e => {
      if ( respuesta === '0' || respuesta === e.resp)
        e.s = z
      else
        e.s = false
      return e
    })
    setPreinscriptos(t)
    setC_E(t.filter(a => a.s).length)
  }

     function update(obj) {
      fetch (back + 'admin/corregir', {
        body: JSON.stringify(obj),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        setForm({...form, id: 0})
        traer_pre()
      })
    }

  function mail() {
    const quien = preinscriptos.filter(e => e.s)
    const pedidosPausados = quien.map(t => {
      const sub_t = {
        apellido: t.apellido,
        nombre: t.nombre,
        email: t.email,
        id: t.id,
        acomp: t.acompaniante
      }
      return () => fetch (back + 'admin/enviar_mail', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        if(a) {
          const desmarcar = preinscriptos.map (z => {
            if (z.id === sub_t.id)
              z.s = false
            return z
          })
          setPreinscriptos(desmarcar);
          const cuantos = desmarcar.filter(a => a.s).length
          setC_E(cuantos)
        }
      });
    });
    pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }

    function enviar_qr() {
      const quien = preinscriptos.filter(e => e.s)
      const pedidosPausados = quien.map(t => {
        const sub_t = {
          apellido: t.apellido,
          nombre: t.nombre,
          email: t.email,
          id: t.id
        };
        return () => fetch (back + 'admin/enviar_mail_con_qr', {
          body: JSON.stringify(sub_t),
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + props.token,
            'Content-Type': 'application/json' 
          }
        })
        .then (response => response.json())
        .then (a => {
          if(a) {
            const desmarcar = preinscriptos.map (z => {
              if (z.id === sub_t.id)
                z.s = false
              return z
            })
            setPreinscriptos(desmarcar);
            const cuantos = desmarcar.filter(a => a.s).length
            setC_E(cuantos)
          }
        });
      });
      pedidosPausados.reduce((p, pnew) => p.then(() => sleep(3000)).then(pnew), Promise.resolve());
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    }

    function eliminar() {
      const t = preinscriptos.filter(e => e.s)
      const sub_t = {id: t[0].id}
      fetch (back + 'admin/eliminar', {
        body: JSON.stringify(sub_t),
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {
        setPreinscriptos(preinscriptos.filter(e => !e.s))
        setC_E(0)
      })
    }

    function descargar() {
      fetch (back + 'admin/descargar', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      })
      .then (response => response.json())
      .then (a => {if (a==='Ok') 
        window.open("https://inscripcion.ar/servicios/datos_cir.csv")
      }
      )
    }
    
  return (<>
        <div className="flex flex-row justify-evenly w-full text-lg font-semibold py-2">
          <select 
            className="px-2 outline-0"
            value={respuesta}
            onChange={e => setRespuesta(e.target.value)}
          >
            <option value="0">Todos</option>
            <option value="1">Pago sin Informar</option>
            <option value="2">Pago Informado</option>
          </select>
          <div className="flex flex-row w-1/6 justify-around">
            {c_e}
            <label>Seleccionados de</label>
            {total}
          </div>
          {props.quien.rol===2 ?
          <button 
            name='Descargar' 
            className="border-2 px-2 border-green-400 bg-green-200 hover:text-white hover:bg-green-800" 
            onClick={() => descargar()}
          >
            Descargar Todo
          </button>
          :
          ''
          }
          {parseInt(respuesta) === 1 ?
          <button 
              name='Email' 
              className="border-2 px-2 border-blue-400 bg-blue-200 text-sm hover:text-white hover:bg-blue-800"
              disabled={c_e >= 1 ? false: true}
              onClick={() => mail()}
              >
              Reenviar Mail
           </button>
           : 
           parseInt(respuesta) === 2 ?
            <button 
            name='Enviar QR' 
            className="border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
            onClick={() => enviar_qr()}
           >
             Enviar QR
         </button>
            : ''}
           {props.quien.rol===2 ? (<>
           <button 
               name='Eliminarr' 
               className={c_e===1 ?
                "border-2 px-2 border-red-400 bg-red-200 text-sm hover:text-white hover:bg-red-800" 
                 :
                 "border-2 px-2 border-gray-400 cursor-not-allowed bg-gray-200"
                }
                disabled={c_e===1 ? false: true}
                onClick={() => eliminar()}
              >
                Eliminar
            </button>
            
         </>)
            :
            ''
            }
        </div>
        
        <div className="pl-2 w-full grid grid-cols-10 bg-orange-200 gap-x-2 place-content-start">
        <div className="">Filtrar</div>
          <div className="flex flex-col w-32">
            <div>Apellido</div>
            <input 
              type="text"
              value={apellido}
              onChange={e => setApellido(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Nombre</div>
            <input 
              type="text"
              value={nombre}
              onChange={e => setNombre(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>E - mail</div>
            <input 
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Documento</div>
            <input 
              type="text"
              value={dni}
              onChange={e => setDni(e.target.value)}
              className="w-20 bg-orange-100 text-sm border-b-2 border-gray-400 outline-0"
            />
          </div>
          <div className="w-32 flex flex-col">
            <div>Categoría</div>
            <select 
              value={categoria}
              onChange={e => setCategoria(e.target.value)}
              className="w-20 bg-orange-100  border-b-2 border-gray-400 outline-0"
            >
             <option value="Todos">Todos</option> 
             <option value="Socio">Sociio</option>
             <option value="NoSocio">No Socio</option>
             <option value="Residente">Residente</option>
             <option value="Estudiante">Estudiante</option>
             <option value="Extranjero">Extranjero</option>
             <option value='Becado'>Becado</option>
            </select>
          </div>
          </div>
          <div className="flex flex-row justify-between w-full text-lg font-semibold border-b-2 border-gray-400">
            <div className="pl-5 grid grid-cols-8">
              <div className="w-4"><input 
                type="checkbox"
                id="0"
                onChange={(e) => todo(e.target.checked)}
              /></div>
              <div className="w-2"></div>
            </div>
            <div className="grid grid-cols-9 w-full place-content-center mx-5">
            <div className="w-1/10">Apellido</div>
            <div className="w-1/10">Nombre</div>
            <div className="col-span-2">Mail</div>
            <div className="">Documento</div>
            <div className="w-1/10">Teléfono</div>
            <div className="1-1/10">ID</div>
            <div className="min-w-1/10">Categoría</div>
            <div className="min-w-1/10">Pago</div>
         </div> 
         </div>
         <div className="px-5 w-full overflow-y-auto text-sm">
        { preinscriptos.map((a) => {
          if (!parseInt(respuesta) || a.pagado === parseInt(respuesta)) 
            if ((a.nombre.toLowerCase().includes((nombre).toLowerCase()) && a.apellido.toLowerCase().includes((apellido).toLowerCase()) && a.email.toLowerCase().includes((email).toLowerCase())) && a.documento.includes((dni)) && (categoria==='Todos' || a.categoria === categoria))      
              return <Inscripto provincias={provincias} key={a.id} obj={a} elegir={elegir} setForm={setForm} form={form} update={update} back={back}/>
          })
        }
        <br></br><br></br>
      </div>
      </>)
  }