import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import DeleteIcon from '@mui/icons-material/Delete'
import LogoutIcon from '@mui/icons-material/Logout'
const Barra = ((props) => {
    return (<>
        <div className="bg-gray-700 flex flex-row justify-between items-center">
            <label className="text-white text-2xl ml-10">Editor de Eventos</label>
            <div className="flex flex-row justify-evenly mr-10">
                <AddCircleOutlineIcon 
                    fontSize='large' 
                    className="text-green-600 m-2"
                    onClick={() => props.setHacer('crear')}
                />
                <DeleteIcon fontSize='large' className="text-red-600 m-2" />
                <LogoutIcon 
                    fontSize='large' 
                    className="text-gray-300 m-2 ml-20 mr-10" 
                    onClick={e => props.setCual(false)}
                />
            </div>
            
        </div>
    </>)
})

export default Barra