import Editar from '@mui/icons-material/Edit'
const TipoEvento = ((props) => {
    const formato_evento = "flex flex-row border-2 border-gray-600 p-2 my-1"
    const formato_evento_elegido = "flex flex-row border-2 border-gray-600 p-2 bg-green-300 my-1 items-center"
    return (<>
        <div className="flex flex-col w-full py-2 items-center">
            <label className="text-lg font-semibold">Tipo de Evento</label>
            <div className="flex flex-col w-3/4">
                {props.evento_t === 'MQR' || props.evento_t === '' ?
                <div className="flex flex-row items-center">
                <div className={props.evento_t === 'MQR' ? formato_evento_elegido : formato_evento} onClick={() => props.setEvento_t('MQR')}>
                    <div className="flex flex-col ml-5">
                        <label className="text-lg text-blue-700">Mailing con QR</label>
                        <label>Solo se envian los mails con la información sobre el evento y el código QR para ingresar. La información se importa por planilla</label>
                    </div>
                </div>
                {props.evento_t === 'MQR' ?
                    <Editar className="ml-5 text-3xl text-red-600" onClick={() => props.setEvento_t('')}/>
                : '' }
                </div>
                : '' }
                
                {props.evento_t === 'FMQR' || props.evento_t === '' ?
                <div className="flex flex-row items-center">
                <div className={props.evento_t === 'FMQR' ? formato_evento_elegido : formato_evento} onClick={() => props.setEvento_t('FMQR')}>
                    <div className="flex flex-col ml-5">
                        <label className="text-lg text-blue-700">Formulario y Mailing con QR</label>
                        <label className="">Formulario para inscripciones con mail de confirmación. Además se envia mail con información del evento y código QR para ingresar</label>
                    </div>
                </div>
                {props.evento_t === 'FMQR' ?
                    <Editar className="ml-5 text-3xl text-red-600" onClick={() => props.setEvento_t('')}/>
                : '' }
                </div>
                : '' }
                
                {props.evento_t === 'FPMQR' || props.evento_t === '' ?
                <div className="flex flex-row items-center">
                <div className={props.evento_t === 'FPMQR' ? formato_evento_elegido : formato_evento} onClick={() => props.setEvento_t('FPMQR')}>
                    <div className="flex flex-col ml-5">
                        <label className="text-lg text-blue-700">Formulario, cobro  y Mailing con QR</label>
                        <label className="">Formulario para inscripciones con mail de confirmación. Permite informar pagos. Además se envia mail con información del evento y código QR para ingresar</label>
                    </div>
                </div>
                {props.evento_t === 'FPMQR' ?
                    <Editar className="ml-5 text-3xl text-red-600" onClick={() => props.setEvento_t('')}/>
                : '' }
                </div>
                : '' }
            </div>
        </div> 
    </>)
})

export default TipoEvento