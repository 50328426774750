import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es-mx';

const Hora = ((props) => {
   return (
    
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">        
        <TimePicker
            slotProps={{ textField: { size: 'small' } }} 
            views={['hours', 'minutes']} 
            value={dayjs(props.hora)} 
            onChange={e => props.setHora(e)}
        />
    </LocalizationProvider>
  )
})
export default Hora 



