import Editar from '@mui/icons-material/Edit'

const Imagen = ((props) => {
    return (<>
    <div className="w-full px-2">
    <div className="flex flex-col justify-center">
        <label className="mr-10 text-lg font-semibold text-center" htmlFor="imagen">Imagen para Encabezado</label>
        <div className="flex flex-row mt-2 justify-center items-center">
            {props.cabecera==='' ? <input type="file" name="imagen" onChange={(e) => props.setCabecera(e.target.files[0])} /> : '' }
            {props.cabecera !== undefined && props.cabecera !== ''?
                <img src={URL.createObjectURL(props.cabecera)} alt="Imagen seleccionada" className="w-1/3"/>
            :
            ''
            }
            {props.cabecera !== '' ?
                <Editar className="ml-5 text-3xl text-red-600" onClick={() => props.setCabecera('')}/>
            : '' }
        </div>
    </div>
    </div>
    </>)
})

export default Imagen