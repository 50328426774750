const Barra = ((props) => {
    return (<>
    {props.quien.rol!==0 ?
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center w-full bg-gray-800 text-white pr-5">
       <div className="justify-between">
         <label className="font-semibold text-2xl pr-5">Evento:</label>
         {props.quien.rol===2 ?
         <select className="outline-0 text-lg text-black" value={props.evento} onChange={e => props.setEvento(parseInt(e.target.value))}>
          {props.eventos.map((a) => {
                return <option key={a.id} value={a.id}>{a.nombre}</option>
            })
         }
         </select>
         : 
         <label className="text-lg">{props.evento_nombre}</label>
        }
       </div>
       <button 
        className="text-lg p-2 my-2 text-green-600 border-2 border-green-600 rounded-xl"
        onClick={() => {if(props.quien.rol===2) props.setCual(true) }}
        >Eventos
        </button>
       <div className="justify-between text-2xl">
         <label className="font-semibold pr-5">Usuario:</label><label>{' ' + props.quien.apellido + ' ' + props.quien.nombre}</label>
       </div>
     </div>
     </div>
     : ''
     }
     </>)
})

export default Barra