import {useState} from 'react'

const ListaEventos = ((props) => {
    const formato_normal = "text-lg px-2 border-2 border-gray-600 hover:border-red-600 hover:text-red-600 my-2 text-center"    
    const formato_elegido = "text-lg font-semibold text-white bg-green-600 px-2 border-2 border-green-600 my-2 text-center rounded-xl"
    const [buscar, setBuscar] = useState('')
    return (<>
    <div className="flex flex-col p-2">
        <div className="flex flex-row justify-center p-2">
            <label className="mr-5">Filtrar</label>
            <input 
                className="outline-0 w-32 focus:border-b-2 focus:border-red-500"
                value={buscar}
                onChange={e => setBuscar(e.target.value)}
            />
        </div>
        {props.eventos.length ?
        <ul>
            {props.eventos.map(a => {
                if (!buscar || a.nombre.toLowerCase().includes(buscar)) {
                    return <li 
                        key={a.id}
                        onClick={() => props.setEvento(a.id)}
                        className={a.id === props.evento ? formato_elegido : formato_normal}
                    >
                {a.nombre}</li> 
                } else {
                    return ''
                }
            })
            }
        </ul>
        : ''
        }
       
    </div>
    </>)
})

export default ListaEventos