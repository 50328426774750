import Fecha from './fecha.js'
import Hora from './hora.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {useState} from 'react'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
const DataEvento = ((props) => {
    const [fecha, setFecha] = useState(new Date())
    const [hora, setHora] = useState(new Date())
    const formato1 = "flex flex-row w-1/2 justify-between  mt-2"
    const formato2 = "w-96 ml-5 outline-0 focus:outline-0 focus:border-b-2 border-red-600 px-2"
    const formato3 = "w-12 ml-5 outline-0 focus:outline-0 focus:border-b-2 border-red-600 px-2"

    const initialValues = {
        asunto: '',
        donde: '',
        from: '',
        letra_qr: ''
      }
    
    const validate = ((values) => {
        const errors = {}
        if (!values.asunto) 
            errors.asunto = 'Asunto requerido'
        else 
            if (! /^[A-ZÁÉÍÓÚÑa-zñáéíóú' ]+([A-ZÁÉÍÓÚÑa-zñáéíóú' ]+)*$/.test(values.asunto))
                errors.asunto = 'Caracteres no válidos'
        if (!values.from) 
            errors.from = '¿Quien envía?'
        else
            if (! /^[A-ZÁÉÍÓÚÑa-zñáéíóú' ]+([A-ZÁÉÍÓÚÑa-zñáéíóú' ]+)*$/.test(values.from))
                errors.from = 'Caracteres no válidos'
        if (!values.donde) 
            errors.donde = 'Ubicación requerida'
        else
            if (! /^[A-ZÁÉÍÓÚÑa-zñáéíóú' ]+([A-ZÁÉÍÓÚÑa-zñáéíóú' ]+)*$/.test(values.donde))
                errors.donde = 'Caracteres no válidos'
        if (!values.letra_qr) 
            errors.letra_qr = 'Código para qr requerido'
        else {
            if (! /^[a-zA-Z]*$/.test(values.letra_qr))
                errors.letra_qr = 'SOLO letras'
            if (values.letra_qr.length < 3)
                errors.letra_qr = 'Mínimo 3 letras'
        }
        return errors;
    })
       
    const onSubmit = ((values, { resetForm }) => {
        var data = new FormData()
        data.append('img', props.imagen)
        var user = {
            body: data,
            method: 'POST'
        }
        fetch(props.back + 'subir_imagen',user)
        .then (response => response.json())
        .then (json => {
            const a = {...values, imagen: json.filename, evento_t: props.evento_t ,nombre: props.nombre, fecha: fecha, hora: hora}
            fetch(props.back + 'admin/crear_evento', {
                body: JSON.stringify(a),
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {
                resetForm()
                props.setRecargar(! props.recargar)
            })
        })      
    })

    return(<>
    {props.nombre.trim()!=='' && props.evento_t!=='' && props.imagen!==''?
    <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
    >
    {() => (
    <Form>
    <div className="px-2 mt-3 text-lg text-center font-semibold">
        Datos para Mail con QR
    </div>
    <div className="px-2 flex flex-col items-center">
        <div className={formato1}>
            <label htmlFor="asuntoo">Asunto</label>
            <div className="flex flex-col">
                <Field className={formato2} type="text" name="asunto" />
                <ErrorMessage name="asunto" component="div" className="text-red-600"/>
            </div>
        </div>
        <div className={formato1}>
            <label htmlFor="from">De quién</label>
            <div className="flex flex-col">
                <Field className={formato2} type="text" name="from" />
                <ErrorMessage name="from" component="div" className="text-red-600"/>
            </div>
        </div>
        <div className={formato1}> 
            <label htmlFor="fecha" className="mr-5">Fecha</label>
            <div className="flex flex-col">
                <Fecha fecha={fecha} setFecha={setFecha} />
                <ErrorMessage name="fecha" component="div" className="text-red-600"/>
            </div>
        </div>  
        <div className={formato1}> 
            <label htmlFor="fecha" className="mr-5">Hora</label>
            <div className="flex flex-col">
                <Hora hora={hora} setHora={setHora} />
                <ErrorMessage name="hora" component="div" className="text-red-600"/>
            </div>
        </div>  
        <div className={formato1}> 
            <label htmlFor="donde" className="mr-5">Donde</label>
            <div className="flex flex-col">
                <Field className={formato2} type="text" name="donde" />
                <ErrorMessage name="donde" component="div" className="text-red-600"/>
            </div>
        </div>  
        <div className={formato1}> 
            <label htmlFor="letra_qr" className="mr-5">QR comienza con</label>
            <div className="flex flex-col">
                <Field className={formato3} type="text" name="letra_qr" />
                <ErrorMessage name="letra_qr" component="div" className="text-red-600"/>
            </div>
        </div>  
        <button type="submit">
        <SaveAltIcon 
            fontSize='large' 
            className="text-blue-600 m-4"
        />
        </button>
    </div>
   </Form>
    )}
</Formik>
: '' }
</>)
})

export default DataEvento