import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/es-mx';

const Fecha = ((props) => {
   return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
      <DatePicker  
        slotProps={{ textField: { size: 'small' } }} 
        format='YYYY - MM - DD' 
        value={dayjs(props.fecha)} 
        onChange={e => props.setFecha(e)} 
        disablePast
      />
    </LocalizationProvider>
  )
})
export default Fecha 