import Barra from './barra.js'
import ListaEventos from './lista_e.js'
import Evento from './evento.js'
import {useState, useEffect} from 'react'

const EditorEventos = ((props) => {
  const PORT_BACK = 8008
  const SERVER_URL = "https://inscripcion.ar:"
  const back = SERVER_URL + PORT_BACK + "/"

  const [eventos, setEventos] = useState([])
  const [evento, setEvento] = useState(0)
  const [evento_t, setEvento_t] = useState('')
  const [hacer, setHacer] = useState('') 
  const [recargar, setRecargar] = useState(false)

  useEffect (() => {
    function traer_eventos () {
      const data = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json' 
        }
      }
      fetch(back + 'admin/traer_eventos', data)
      .then (response => response.json())
      .then (json => setEventos(json))
    }
    traer_eventos()
  }, [props.token, recargar, back])
  
  
  
  
  return (<>
    <div className="w-screen h-screen bg-gray-300">
      <Barra setHacer={setHacer} setCual={props.setCual} />
      <div className="flex flex-row h-full w-full">
        <div className="w-1/6 border-r-2 border-gray-600 px-2">
          <ListaEventos setEvento={setEvento} evento={evento} eventos={eventos}/>
        </div>
        <div className="w-5/6 h-full overflow-y-auto">
          <Evento token={props.token} hacer={hacer} evento={eventos} back={back} evento_t={evento_t} setEvento_t={setEvento_t} recargar={recargar} setRecargar={setRecargar} />
        </div>
      </div>
    </div>
  </>)   
})

export default EditorEventos


